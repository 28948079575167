import VxAppBarSearchDesktop from "./VxAppBarSearchDesktop";
import VxAppBarSearchMobile from "./VxAppBarSearchMobile";

export default {
  functional: true,
  inheritAttrs: false,
  render(h, context) {
    const responsiveComponent = context.parent.$vuetify.breakpoint.smAndDown
      ? VxAppBarSearchMobile
      : VxAppBarSearchDesktop;

    return h(
      responsiveComponent,
      {
        props: context.props,
        ...context.data,
        scopedSlots: context.scopedSlots,
      },
      context.$slots.default
    );
  },
};
