<script>
import VxAppBarBase from "./VxAppBarBase";
import RightMenu from "@/components/RightMenu";

export default {
  name: "VxAppBarDesktop",
  components: {
    RightMenu,
    StoreSelect: () => import("@/components/StoreSelect"),
    LockButton: () => import("@/components/lock/LockButton"),
    EmployeeAvatar: () => import("@/components/employees/EmployeeAvatar.vue"),
  },
  extends: VxAppBarBase,
  inheritAttrs: false,
};
</script>

<template>
  <v-app-bar
    app
    elevate-on-scroll
    extension-height="42"
    :clipped-right="$vuetify.breakpoint.mdAndUp"
    v-bind="$props"
    v-on="$listeners"
  >
    <slot>
      <slot name="search" :back="() => {}">
        <slot name="title">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </slot>
      </slot>
      <v-spacer />
      <slot name="overflow">
        <slot name="overflowExtras" />
        <template v-if="teamMode">
          <LockButton />
          <v-fade-transition leave-absolute>
            <EmployeeAvatar
              v-if="employee"
              :employee="employee"
              status-size="14"
              size="32"
              tooltip
            />
          </v-fade-transition>
        </template>
        <StoreSelect v-else />
        <RightMenu>
          <template #items>
            <slot name="rightMenuItems" />
          </template>
        </RightMenu>
      </slot>
    </slot>
    <slot slot="extension" name="extension"></slot>
  </v-app-bar>
</template>
