<template>
  <v-menu v-model="open" offset-y :close-on-content-click="false">
    <template #activator="{ attrs, on }">
      <v-btn data-testid="mobileAppBarSortButton" icon v-bind="attrs" v-on="on">
        <v-icon>$sort</v-icon>
      </v-btn>
    </template>
    <v-list v-scroll="handleScroll">
      <v-list-item-group :value="currentSorting" color="primary">
        <v-list-item
          v-for="(option, idx) in sortOptions"
          :key="idx"
          :value="option.value"
          @click="handleChangeSort(option.value)"
        >
          <v-list-item-title>
            {{ option.text }}
          </v-list-item-title>
          <v-list-item-icon>
            <v-icon
              v-if="option.value === currentSorting"
              v-text="sortIcon"
            ></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "VxAppBarMobileSort",
  props: {
    sortOptions: {
      type: Array,
      default: () => [],
    },
    defaultSorting: {
      type: String,
      default: "",
    },
    defaultOrder: {
      type: String,
      default: "asc",
    },
  },
  data: () => ({
    open: false,
  }),
  computed: {
    currentSorting() {
      return this.$route.query.sort || this.defaultSorting;
    },
    currentOrder() {
      return this.$route.query.order || this.defaultOrder;
    },
    sortIcon() {
      return this.currentOrder === "asc" ? "$asc" : "$desc";
    },
  },
  methods: {
    handleChangeSort(val) {
      let newOrder;
      if (val === this.currentSorting) {
        newOrder = this.currentOrder === "asc" ? "desc" : "asc";
      } else {
        newOrder = undefined;
      }

      if (newOrder === this.defaultOrder) {
        newOrder = undefined;
      }

      if (val === this.defaultSorting) {
        val = undefined;
      }
      this.$router.push({
        query: { sort: val, order: newOrder },
        preserveQuery: true,
      });
    },
    handleScroll(e) {
      this.open = false;
    },
  },
};
</script>
