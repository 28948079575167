<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="ui-l2">
        <div class="text-h6" v-text="title" />
      </v-card-title>
      <v-card-text>
        <slot name="body"></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "VxDialogSmall",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        return this.$emit("input", v);
      },
    },
  },
};
</script>

<style></style>
