<template>
  <v-autocomplete
    ref="select"
    :autofocus="autofocus"
    :items="locationItems"
    :label="label"
    item-value="id"
    item-text="nameWithCount"
    :value="value"
    :rules="(noValidate && []) || localRules"
    :return-object="returnObject"
    :disabled="disabled"
    clearable
    placeholder="Location"
    :loading="loading"
    @input="update"
  >
    <template slot="selection" slot-scope="props">
      {{ props.item.name }}
    </template>
  </v-autocomplete>
</template>

<script>
import { backstockSelect } from "../mixins/backstock";
export default {
  name: "LocationSelect",
  mixins: [backstockSelect],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localRules: [
        (v) =>
          (v && this.locationItems.map((c) => c.id).includes(v.id)) ||
          "A valid location is required ",
        ...this.rules,
      ],
    };
  },
  computed: {
    locationItems() {
      if (this.disabled) return [this.value];
      return this.items || this.locations || [];
    },
  },
};
</script>
