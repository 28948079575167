import { mapState } from "vuex";
import { VAppBar } from "vuetify/lib";
import { STORE_SELECT_STORES } from "@/graphql/store/queries.gql";

export default {
  name: "VxAppBarBase",
  extends: VAppBar,
  inject: ["computedEmployees"],
  props: {
    title: {
      type: String,
      default: "",
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    advancedSearchable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("auth", ["teamModeLock", "teamMode", "employeeId"]),
    employee() {
      if (!this.employeeId) {
        return;
      }

      const employeeIdString = this.employeeId.toString();
      return this.computedEmployees().find((e) => e.id === employeeIdString);
    },
  },
};
