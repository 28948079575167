<script>
import VxAppBar from "@/components/vx/VxAppBar";
import VxAppBarSearch from "@/components/vx/VxAppBarSearch";
import BackstockAppBarExtension from "./components/BackstockAppBarExtension";
import EmployeeAction from "@/components/employees/EmployeeAction.js";
import { columns, defaultOrder, defaultSortPath } from "./utils/columns";
import VxAppBarMobileSort from "@/components/vx/VxAppBarMobileSort.vue";

export default {
  name: "BackstockAppBar",
  components: {
    VxAppBar,
    VxAppBarSearch,
    BackstockAppBarExtension,
    EmployeeAction,
    VxAppBarMobileSort,
  },
  props: {
    // Should not be removed because it is used by the extension
    // If not defined here will not be sent to the extension component
    storeId: {
      type: String,
      default: undefined,
    },

    categoryId: {
      type: String,
      default: undefined,
    },
    locationId: {
      type: String,
      default: undefined,
    },
    filter: {
      type: String,
      default: undefined,
    },
    parsedFilter: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    appBarTitle() {
      return this.$t("backstock.app.toolbar.title");
    },
    sortOptions() {
      return columns
        .filter((column) => column.sortable)
        .map((column) => ({
          value: column.propertyPath,
          text: this.$t(column.labelPath),
        }));
    },
    defaultSorting() {
      return defaultSortPath;
    },
    defaultOrder() {
      return defaultOrder;
    },
  },
  methods: {
    handleClick() {
      this.$router.push({ name: "backstock.setup" });
    },
  },
};
</script>

<template>
  <VxAppBar :title="appBarTitle" mobile-search :filter="filter">
    <template #search="{ back }">
      <VxAppBarSearch
        :title="appBarTitle"
        :min-chars="3"
        :filter="filter"
        :parsed-filter="parsedFilter"
        @back="back"
      />
    </template>

    <template #overflow-extension>
      <VxAppBarMobileSort
        :sort-options="sortOptions"
        :default-sorting="defaultSorting"
        :default-order="defaultOrder"
      />
    </template>

    <template #extension>
      <BackstockAppBarExtension v-bind="$props" />
    </template>

    <template #rightMenuItems>
      <EmployeeAction
        v-slot="{ handleAction, isDisabled, listeners }"
        check-manager
      >
        <v-list-item ripple v-on="listeners" @click="handleAction(handleClick)">
          <v-list-item-title :class="{ 'text--disabled': isDisabled }">
            {{ $t("backstock.app.toolbar.settingsMenuTitle") }}
          </v-list-item-title>
        </v-list-item>
      </EmployeeAction>
    </template>
  </VxAppBar>
</template>
