<template>
  <v-container fluid class="rai-empty-state fill-height">
    <v-row
      class="fill-height"
      align="center"
      align-content="center"
      justify="space-around"
    >
      <slot></slot>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EmptyState",
  props: {
    imgSrc: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
.rai-empty-state {
  color: var(--v-ui-lighten1);
}
</style>
