// Returns an object of { bins: [], ands: [] }
// -  bins gets populated by comma-separated strings
// -  if a string contains spaces, that string is split on spaces,
//    then the first element is returned. so this assumes the bin name
//    comes first in the space-separated string.
//    Example:
//      this works
//      `m0001, m0003 shoes, m0005` << this will return { bins: ["m0001", "m0003", "m0005"] ands: ["shoes"] }
//      this doesn't
//      `m0001, shoes m0003, m0005` << this will return { bins: ["m0001", "shoes", "m0005"], ands: ["m0003"] }
const parseString = (s) => {
  const clean = s
    .split(/,\s*/)
    .filter((e) => e)
    .map((e) => e.trim());

  if (clean.length === 1) return { bins: [], ands: clean };

  return clean.reduce(
    (acc, e) => {
      // if the current string contains a space, assume
      if (e.match(/\s/)) {
        const split = e.split(" ");
        acc.bins.push(split[0]);
        acc.ands.push(split.slice(1).join(" "));
        return acc;
      }
      acc.bins.push(e);
      return acc;
    },
    { bins: [], ands: [] }
  );
};

// Uses `parseString` to build a Regular Expression where bin names
// are "OR" filters and other strings are "AND" filters
// Example:
// `m0001, m0003, m0005 shoes` will find all entries where
// (bin name = "m0001" OR "m0003" OR "m0005") AND bin contains the text "shoes"
const buildRegex = (s) => {
  if (s.indexOf(",") > -1) {
    let { bins, ands } = parseString(s);
    ands = ands.map((e) => `(?=.*${e})`);
    return new RegExp(`(?<=${bins.join("|")})${ands.join("")}`, "i");
  }
  return new RegExp(
    "^" +
      s
        .split(" ")
        .map((e) => `(?=.*${e})`)
        .join(""),
    "i"
  );
};

export { buildRegex, parseString };
