<script>
import SimpleSelect from "./SimpleSelect";
import { chain, union, get, debounce } from "lodash";
import { blurActiveElement } from "@/mixins/ui";
import {
  backstocksQuery,
  binsQuery,
  categoriesQuery,
  locationsQuery,
} from "../mixins/backstock.js";
import SetLocationDialog from "./SetLocationDialog";
import AddNoteDialog from "./AddNoteDialog";

import { buildRegex } from "../utils/string-parsing";

export default {
  name: "BackstockAppBarExtension",
  components: {
    SimpleSelect,
    SetLocationDialog,
    AddNoteDialog,
  },
  mixins: [
    blurActiveElement,
    backstocksQuery,
    binsQuery,
    categoriesQuery,
    locationsQuery(),
  ],
  props: {
    categoryId: { type: String, default: "" },
    locationId: { type: String, default: "" },
    storeId: { type: String, default: "" },
  },
  data: () => {
    return {
      loading: false,
      setLocationDialog: false,
      addNoteDialog: false,
    };
  },
  computed: {
    search: {
      get() {
        return this.$route.query.filter;
      },
      // This is debounced so it doesn't feel so sluggish
      set: debounce(function (v) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, { filter: v }),
        });
      }, 700),
    },
    searchRegex() {
      let str = this.search || "";
      return buildRegex(str);
    },
    backstockQueryVariables() {
      return {
        storeId: this.storeId,
      };
    },
    backstockBinNames() {
      return this.allBackstocks?.map(({ bin }) => bin.name.toLowerCase());
    },
    binNames() {
      return this.bins
        ?.map(({ name }) => name && name.toLowerCase())
        .filter(Boolean);
    },
    allBinNames() {
      return union(this.binNames, this.backstockBinNames);
    },
    hasBinSearch() {
      return !!(
        this.search &&
        this.search
          .split(/,\s*/)
          .filter((e) => e.length)
          .some((term) => this.allBinNames.includes(term.trim().toLowerCase()))
      );
    },
    hasBinSearchResults() {
      return !!(this.hasBinSearch && this.backstocks.length > 0);
    },
    backstocks() {
      const list = this.allBackstocks?.filter(
        (b) =>
          (!this.categoryId || b.category.id === this.categoryId) &&
          (!this.locationId || b.location.id === this.locationId) &&
          (!this.search || b.searchKey.match(this.searchRegex))
      );
      const lastIndex = list.length - 1;
      const lastBackstock = list[lastIndex];
      list[lastIndex] = { ...lastBackstock, isLast: true };
      return list;
    },
    locationsFilteredByCategory() {
      return this.typeFilteredByOther("location", "locations", "category");
    },
    locationsForTableFilter() {
      return this.locationsFilteredByCategory.filter(
        (l) => l.terminal !== true
      );
    },
    categoriesFilteredByLocation() {
      return this.typeFilteredByOther("category", "categories", "location");
    },
    categoryActiveIds() {
      return this.backstocks.map((b) => b.category.id);
    },
    locationActiveIds() {
      return this.backstocks.map((b) => b.location.id);
    },
  },
  methods: {
    categoryChanged(newCategoryId) {
      const routeToNavigateTo = {
        name: this.$route.name,
        query: {
          categoryId: newCategoryId,
        },
        preserveQuery: true,
      };
      this.gotoRouteIfChanged(routeToNavigateTo);
    },
    locationChanged(newLocationId) {
      const routeToNavigateTo = {
        name: this.$route.name,
        query: {
          locationId: newLocationId,
        },
        preserveQuery: true,
      };
      this.gotoRouteIfChanged(routeToNavigateTo);
    },
    gotoRouteIfChanged(routeToNavigateTo) {
      // First check if the route changed and navigate only if changed.
      // We do this because it can happen that v-autocomplete triggers a change 2 times
      // And also in any case if the route did not change - no reason to navigate to new one.
      if (
        this.$router.resolve(routeToNavigateTo)?.resolved?.fullPath !==
        this.$route.fullPath
      ) {
        this.$router.push(routeToNavigateTo);
      }
    },
    // `type`   -> "category" || "location"
    // `source` -> "categories" || "locations"
    // `other`  -> "location" || "category"
    // This is shared code for filtering locations by category and vice versa
    typeFilteredByOther(type, source, other) {
      const addCountToName = this.addCountToTypeName(type);

      return chain(this[source])
        .map(addCountToName)
        .filter((item) => this[`${type}ActiveIds`].includes(item.id))
        .uniqBy("id")
        .sortBy(["sortOrder"])
        .value();
    },
    addCountToTypeName(type) {
      return (item) => ({
        ...item,
        nameWithCount: `${item.name} (${
          this.backstocks.filter((b) => b[type].id === item.id).length
        })`,
      });
    },
  },
};
</script>

<template>
  <div v-if="hasBinSearchResults">
    <v-btn color="secondary" text @click="setLocationDialog = true">
      {{ $t("backstock.app.toolbarExtension.setLocationButtonText") }}
    </v-btn>
    <v-btn color="secondary" text @click="addNoteDialog = true">
      {{ $t("backstock.app.toolbarExtension.addNoteButtonText") }}
    </v-btn>

    <SetLocationDialog
      v-model="setLocationDialog"
      :backstocks="backstocks"
      :search="search"
    />
    <AddNoteDialog
      v-model="addNoteDialog"
      :backstocks="backstocks"
      :search="search"
    />
  </div>
  <div v-else>
    <v-row>
      <v-col cols="6" md="6" class="pt-3">
        <div class="mr-2 my-0">
          <SimpleSelect
            :value="categoryId"
            :items="categoriesFilteredByLocation"
            :placeholder="
              $t('backstock.app.toolbarExtension.categoryPlaceholder')
            "
            hide-details
            @input="categoryChanged"
            @change="blurActiveElement"
            @keyup.enter="blurActiveElement"
          />
        </div>
      </v-col>
      <v-col cols="6" md="6" class="pt-3">
        <div class="ml-2 my-0">
          <SimpleSelect
            :value="locationId"
            :items="locationsForTableFilter"
            :placeholder="
              $t('backstock.app.toolbarExtension.locationPlaceholder')
            "
            hide-details
            @input="locationChanged"
            @change="blurActiveElement"
            @keyup.enter="blurActiveElement"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
