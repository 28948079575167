<script>
import VxAppBarSearchBase from "./VxAppBarSearchBase";

export default {
  name: "VxAppBarSearchMobile",
  extends: VxAppBarSearchBase,
  computed: {
    inputStyle() {
      return {
        borderRadius: "10px",
      };
    },
  },
};
</script>

<template>
  <v-menu
    v-model="menuOpened"
    offset-y
    nudge-bottom="10"
    :close-on-content-click="false"
    content-class="mobile-app-bar-menu"
    transition="slide-y-transition"
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="searchField"
        data-testid="appBarSearchField"
        hide-details
        solo
        single-line
        clearable
        autofocus
        placeholder="Search"
        :style="inputStyle"
        :readonly="fieldReadOnly"
        @click="openMenuIfAdvancedFilter"
        @focus="setFocus"
        @blur="clearFocus"
        @click:clear="clearFilter"
      >
        <template #prepend-inner>
          <v-icon class="mr-3" @click="$emit('back')">$back</v-icon>
        </template>
        <template #append>
          <v-icon v-if="advanced" v-on="on"> $filterMenu </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-card-text>
        <slot name="advanced" :update="update" :cancel="cancel" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<style lang="scss">
.mobile-app-bar-menu {
  width: 100vw !important;
  max-width: 100vw !important;
  max-height: calc(100vh - 70px) !important; // -> 70px VxAppBarMobile height
}
</style>
