<script>
import { mapMutations } from "vuex";
import VxAppBarBase from "./VxAppBarBase";
import RightMenu from "@/components/RightMenu";

export default {
  name: "VxAppBarMobile",
  components: { RightMenu },
  extends: VxAppBarBase,
  inheritAttrs: false,
  props: {
    height: {
      type: [Number, String],
      default: 70,
    },
    mobileSearch: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    searching: false,
  }),
  computed: {
    showSearchFilter() {
      return !this.searching && (!!this.filter || this.isAdvancedFilterPresent);
    },
    showSearchButton() {
      return !this.searching && this.mobileSearch;
    },
  },
  methods: {
    ...mapMutations("ui", ["toggleMobileDrawer"]),
    search() {
      this.searching = true;
    },
    back() {
      this.searching = false;
    },
  },
};
</script>

<template>
  <v-app-bar
    app
    elevate-on-scroll
    extension-height="42"
    v-bind="$props"
    v-on="$listeners"
  >
    <slot>
      <template v-if="searching">
        <slot name="search" :back="back" />
      </template>
      <template v-else>
        <slot name="navicon">
          <v-app-bar-nav-icon @click="toggleMobileDrawer">
            <v-icon>$menu</v-icon>
          </v-app-bar-nav-icon>
        </slot>
        <slot name="title">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </slot>
      </template>
      <slot name="overflow">
        <v-spacer v-if="!searching"></v-spacer>
        <v-icon v-if="showSearchFilter">$filterCheck</v-icon>
        <template v-if="showSearchButton">
          <v-btn data-testid="appBarSearchButton" icon @click="search">
            <v-icon>$search</v-icon>
          </v-btn>
        </template>
        <slot name="overflow-extension" />
        <RightMenu>
          <template #items>
            <slot name="rightMenuItems" />
          </template>
        </RightMenu>
      </slot>
      <slot slot="extension" name="extension" />
    </slot>
  </v-app-bar>
</template>
