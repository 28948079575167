<template>
  <VxDialogSmall v-model="dialog" :title="title">
    <template slot="body">
      <LocationSelect
        v-model="newLocation"
        no-validate
        :label="$t('backstock.app.setLocationDialog.locationLabel')"
      />
      <div v-if="binsNotAvailable.length > 0" class="error--text">
        {{ binsNotAvailableText }}
        <br />
        <br />
        <strong>
          <span v-text="binsNotAvailable.join(', ')" />
        </strong>
      </div>
    </template>
    <template slot="actions">
      <v-spacer />
      <v-btn color="ui" text @click="close">{{
        $t("backstock.app.setLocationDialog.cancelButtonText")
      }}</v-btn>
      <v-btn
        :loading="loading"
        color="primary"
        :disabled="!newLocation"
        text
        @click="save"
      >
        {{ $t("backstock.app.setLocationDialog.okButtonText") }}
      </v-btn>
    </template>
  </VxDialogSmall>
</template>

<script>
import LocationSelect from "./LocationSelect";
import VxDialogSmall from "@/components/vx/VxDialogSmall";
import { BATCH_UPDATE_BACKSTOCK } from "../graphql";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { get, compact } from "lodash";
export default {
  name: "SetLocationDialog",
  components: {
    LocationSelect,
    VxDialogSmall,
  },
  mixins: [hasSnackbarAccess],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    backstocks: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    newLocation: null,
    loading: false,
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    title() {
      return this.$tc(
        "backstock.app.setLocationDialog.title",
        this.backstocks.length
      );
    },
    variables() {
      return {
        input: {
          backstockIds: this.backstocks.map(({ id }) => id),
          locationId: get(this.newLocation, "id"),
        },
      };
    },
    searchBins() {
      return compact(this.search.split(",").map((e) => e.toLowerCase().trim()));
    },
    selectedBins() {
      return this.backstocks.map(({ bin: { name } }) => name.toLowerCase());
    },
    binsNotAvailable() {
      return this.searchBins.filter((b) => !this.selectedBins.includes(b));
    },
    binsNotAvailableText() {
      return this.$tc(
        "backstock.app.setLocationDialog.binsNotAvailableText",
        this.binsNotAvailable.length
      );
    },
  },
  methods: {
    close() {
      this.newLocation = null;
      this.dialog = false;
      this.loading = false;
    },
    save() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: BATCH_UPDATE_BACKSTOCK,
          variables: { ...this.variables },
        })
        .then(() => true)
        .catch((error) => {
          this.showSnackbar({
            text: this.$t("backstock.app.setLocationDialog.errorMessage", {
              error,
            }),
          });
          return false;
        })
        .then((wasSuccessful) => {
          this.loading = false;
          if (wasSuccessful) {
            this.showSnackbar({
              text: this.$t("backstock.app.setLocationDialog.successMessage"),
            });
            return this.close();
          }
        });
    },
  },
};
</script>

<style></style>
