import { debounce, trim } from "lodash";
import {
  buildAdvancedSearchText,
  buildFilterQueryString,
} from "@/utils/filter";

export default {
  props: {
    title: {
      type: String,
      default: "Search...",
    },
    advanced: {
      type: Boolean,
      default: false,
    },
    minChars: {
      type: Number,
      default: 0,
    },
    filter: {
      type: String,
      default: undefined,
    },
    parsedFilter: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    internalMenuOpened: false,
    focused: false,
  }),
  computed: {
    isAdvancedFilterPresent() {
      return (
        this.parsedFilter &&
        this.parsedFilter.filterPresent &&
        this.parsedFilter.isAdvanced
      );
    },
    menuOpened: {
      get() {
        return this.internalMenuOpened;
      },
      set(newValue) {
        if (newValue) {
        } else {
        }
        this.internalMenuOpened = newValue;
      },
    },
    searchField: {
      get() {
        if (!this.parsedFilter || !this.parsedFilter.filterPresent) {
          return undefined;
        }

        if (this.parsedFilter.isAdvanced) {
          return buildAdvancedSearchText(this.parsedFilter.data);
        } else {
          return this.parsedFilter.data;
        }
      },
      set(newValue) {
        if (!!newValue) {
          const searchValueParsed = trim(newValue);
          this.setFilterDebounce(searchValueParsed);
        } else {
          this.setFilterDebounce(undefined);
        }
      },
    },
    fieldReadOnly() {
      return (
        this.parsedFilter &&
        this.parsedFilter.filterPresent &&
        this.parsedFilter.isAdvanced
      );
    },
  },
  methods: {
    // TODO - when chaning filter - happens that it is overriden by old debunce or something.
    // try writing more and less that 3 characthers.
    setFilterDebounce: debounce(function (newValue) {
      if (newValue !== this.filter) {
        if (newValue === undefined || newValue.length >= this.minChars) {
          this.$router.push({
            query: {
              filter: newValue,
            },
            preserveQuery: true,
          });
        }
      }
    }, 500),
    clearFilter() {
      // When user click the clear button, we don't want to debuce and wait, so this is implemented directly.
      this.$router.push({
        query: {
          filter: undefined,
        },
        preserveQuery: true,
      });
    },
    openMenuIfAdvancedFilter() {
      if (this.isAdvancedFilterPresent) {
        this.activateMenu();
      }
    },
    setFocus() {
      this.focused = true;
    },
    clearFocus() {
      this.focused = false;
    },
    activateMenu() {
      this.menuOpened = true;
    },
    deactivateMenu() {
      this.menuOpened = false;
    },
    cancel() {
      this.menuOpened = false;
    },
    update(searchValue) {
      // TODO - use like this everywhere where navigation on same route
      this.$router.push({
        query: {
          filter: buildFilterQueryString(searchValue),
        },
        preserveQuery: true,
      });

      this.menuOpened = false;
    },
  },
};
