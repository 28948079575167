<template>
  <v-autocomplete
    ref="select"
    v-model="localValue"
    v-bind="$attrs"
    :items="items"
    item-value="id"
    item-text="nameWithCount"
    clearable
    dense
    :placeholder="placeholder"
    flat
    v-on="$listeners"
  >
    <template #no-data>
      <EmptyState>
        <slot name="emptyState">No records found</slot>
      </EmptyState>
    </template>
  </v-autocomplete>
</template>

<script>
import EmptyState from "@/components/core/EmptyState";

export default {
  inheritAttr: false,
  components: {
    EmptyState,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    placeholder: {
      type: String,
      required: true,
    },
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
