import { SORT_ORDER } from "@/core-ui";

export const columns = [
  {
    propertyPath: "bin.name",
    labelPath: "backstock.app.dataList.binColumn",
    sortable: true,
    cssClass: "name-cell",
  },
  {
    propertyPath: "category.name",
    labelPath: "backstock.app.dataList.categoryColumn",
    sortable: true,
    cssClass: "category-cell",
  },
  {
    propertyPath: "invDate",
    labelPath: "backstock.app.dataList.ageColumn",
    sortable: true,
    align: "right",
    cssClass: "inv-date-cell",
  },
  {
    propertyPath: "notes",
    labelPath: "backstock.app.dataList.notesColumn",
    sortable: true,
    cssClass: "notes-cell",
  },
  {
    propertyPath: "location.name",
    labelPath: "backstock.app.dataList.locationColumn",
    sortable: true,
    cssClass: "location-cell",
  },
  {
    propertyPath: "nextLocation.name",
    labelPath: "backstock.app.dataList.moveBackstockColumn",
    sortable: false,
    cssClass: "next-location-cell",
  },
];

export const defaultSortPath = "invDate";
export const defaultOrder = SORT_ORDER.ASC;
