import { get, sortBy } from "lodash";

import { isFuture, format, parseISO } from "date-fns";
import { LOCATIONS, BINS, CATEGORIES, BACKSTOCKS } from "../graphql";

export const queryComputedProperties = {
  computed: {
    categories() {
      if (this.backstockQuery === undefined) return [];
      return get(this.backstockQuery, "categories", []).map((c) => {
        c.nameWithCount = `${c.name} (${c.backstocksCount})`;
        return c;
      });
    },
    bins() {
      if (this.backstockQuery === undefined) return [];
      return get(this.backstockQuery, "bins", []);
    },
    locations() {
      if (this.backstockQuery === undefined) return [];
      return get(this.backstockQuery, "locations", []).map((l) => {
        l.nameWithCount = `${l.name} (${l.binCount})`;
        return l;
      });
    },
  },
};

export const backstockRules = {
  data() {
    return {
      rules: {
        bin: [
          (v) =>
            (v && this.bins.map((b) => b.id).includes(v)) ||
            "A valid bin is required",
        ],
        category: [
          (v) =>
            (v && this.categories.map((c) => c.id).includes(v)) ||
            "A valid category is required",
        ],
        location: [
          (v) =>
            (v && this.locations.map((l) => l.id).includes(v)) ||
            "A valid loaction is required",
        ],
        invDate: [
          (v) =>
            (v && !isFuture(parseISO(v))) ||
            "A future date is not a valid option",
        ],
      },
    };
  },
};

export const backstockSelect = {
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: undefined,
    },
    noValidate: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: null,
    },
  },
  methods: {
    get,
    update(selectedValue) {
      this.$emit("input", selectedValue);
    },
    focus() {
      this.$refs.select.focus();
    },
  },
  apollo: {
    bins() {
      return {
        query: BINS,
        variables: {
          storeId: this.$store.getters["auth/activeStoreId"].toString(),
        },
        fetchPolicy: "cache-first",
        skip() {
          return !this.$store.getters["auth/activeStoreId"];
        },
      };
    },
    categories() {
      return {
        query: CATEGORIES,
        variables: {
          storeId: this.$store.getters["auth/activeStoreId"].toString(),
        },
        fetchPolicy: "cache-first",
        update({ categories = [] }) {
          return (
            (categories &&
              categories.map((c) => {
                c.nameWithCount = `${c.name} (${c.backstocksCount})`;
                return c;
              })) ||
            []
          );
        },
        skip() {
          return !this.$store.getters["auth/activeStoreId"];
        },
      };
    },
    locations() {
      return {
        query: LOCATIONS,
        variables: {
          storeId: this.$store.getters["auth/activeStoreId"].toString(),
        },
        fetchPolicy: "cache-first",
        update({ locations = [] }) {
          locations = locations || [];
          return sortBy(
            (locations || []).map((l) => {
              l.nameWithCount = `${l.name} (${l.binCount})`;
              return l;
            }),
            (loc) => loc?.sortOrder
          );
        },
        skip() {
          return !this.$store.getters["auth/activeStoreId"];
        },
      };
    },
  },
};

export const backstockMethods = {
  methods: {
    backstockPayload(backstock) {
      // Destructure the backstock object
      let {
        bin: { id: binId },
        category: { id: categoryId },
        location: { id: locationId },
        notes,
        invDate,
        archived,
      } = Object.assign({}, backstock);

      // Convert the date
      invDate = format(parseISO(invDate), "yyyy-MM-dd");

      // Return the payload
      return {
        binId,
        categoryId,
        locationId,
        notes,
        invDate,
        archived,
      };
    },
  },
};

export const backstocksQuery = {
  apollo: {
    allBackstocks() {
      return {
        query: BACKSTOCKS,
        variables: {
          storeId: this.$store.getters["auth/activeStoreId"].toString(),
        },
        fetchPolicy: "cache-first",
        skip() {
          return !this.$store.getters["auth/activeStoreId"];
        },
        update(data) {
          const backstocks = (data.backstocks || []).map((b) => {
            b.searchKey = `${b.bin.name} ${b.location.name} ${
              b.category.name
            } ${b.notes || ""}`.trim();
            return b;
          });
          return backstocks;
        },
      };
    },
  },
};

export const binsQuery = {
  apollo: {
    bins() {
      return {
        query: BINS,
        variables: {
          storeId: this.$store.getters["auth/activeStoreId"].toString(),
        },
        fetchPolicy: "cache-first",
        skip() {
          return !this.$store.getters["auth/activeStoreId"];
        },
      };
    },
  },
};

export const categoriesQuery = {
  apollo: {
    categories() {
      return {
        query: CATEGORIES,
        variables: {
          storeId: this.$store.getters["auth/activeStoreId"].toString(),
        },
        fetchPolicy: "cache-first",
        update({ categories = [] }) {
          return (
            (categories &&
              categories.map((c) => {
                c.nameWithCount = `${c.name} (${c.backstocksCount})`;
                return c;
              })) ||
            []
          );
        },
        skip() {
          return !this.$store.getters["auth/activeStoreId"];
        },
      };
    },
  },
};

function locationsDefaultUpdateFn({ locations = [] }) {
  return locations.map((l) => {
    l.nameWithCount = `${l.name} (${l.binCount})`;
    return l;
  });
}

export const locationsQuery = (update = locationsDefaultUpdateFn) => {
  return {
    apollo: {
      locations() {
        return {
          query: LOCATIONS,
          variables: {
            storeId: this.$store.getters["auth/activeStoreId"].toString(),
          },
          fetchPolicy: "cache-first",
          update,
          skip() {
            return !this.$store.getters["auth/activeStoreId"];
          },
        };
      },
    },
  };
};
