<script>
import VxAppBarSearchBase from "./VxAppBarSearchBase";

export default {
  name: "VxAppBarSearchDesktop",
  extends: VxAppBarSearchBase,
  computed: {
    inputStyle() {
      return {
        borderRadius: this.menuOpened ? "0px" : "10px",
      };
    },
  },
};
</script>

<template>
  <v-menu
    ref="menu"
    v-model="menuOpened"
    nudge-bottom="1"
    offset-y
    :close-on-content-click="false"
    transition="slide-y-transition"
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="searchField"
        data-testid="appBarSearchField"
        :flat="!focused"
        hide-details
        solo
        single-line
        clearable
        :placeholder="title"
        :readonly="fieldReadOnly"
        :style="inputStyle"
        @click="openMenuIfAdvancedFilter"
        @focus="setFocus"
        @blur="clearFocus"
        @click:clear="clearFilter"
      >
        <template #prepend-inner>
          <v-icon class="mr-3">$search</v-icon>
        </template>
        <template #append>
          <v-icon v-if="advanced" v-on="on"> $filterMenu </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-card-text>
        <slot name="advanced" :update="update" :cancel="cancel" />
      </v-card-text>
    </v-card>
  </v-menu>
</template>
